body {
}

.navbar,
.navbar-default {
    background-color: white !important;
    font-family: 'Segoe UI';
    font-size: 17.13px;
    font-weight: 700;
    padding-left: 10%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}


.ms-Persona {
    margin: auto !important;
    position: relative !important;
    justify-content: center !important;
}

.ms-Persona-image {
    width: 32px !important;
    height: 32px !important;
    margin: auto !important;
}

.ms-Persona-imageArea {
    width: 32px !important;
    height: 32px !important;
}

.ms-Persona-initials {
    width: 32px !important;
    height: 32px !important;
    line-height: 30px !important;
    font-size: 16px !important;
}

.ms-Persona-details {
    display: none !important;
}

.menu-band {
    height: 48px !important;
    
    color: white !important;
    display: flex !important;
}

.menu-button {
    line-height: 48px !important;
    min-width: 48px !important;
    width: auto !important;
    height: 100% !important;
}

.menu-button-alt {
    line-height: 48px !important;
    min-width: 48px !important;
    width: auto !important;
    display: inline-flex !important;
    margin: 5px !important;
}

.menu-name {
    line-height: 48px !important;
    vertical-align: middle !important;
}

.ms-ChoiceField {
    padding-right: 20px;
}

.ms-ChoiceFieldGroup-flexContainer {
    display: flex;
}

.left {
    float: left;
}

.right {
    overflow: hidden;
}

.modusActionBar {
    min-height: calc(100vh - 48px);
    background-color: #f3f2f1;
    /*box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);*/
    border-right: 1px solid rgb(210, 208, 206);
}

.modusImage {
    margin-left: 48px;
}



